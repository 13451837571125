<template>
  <div>
    <!-- Filters -->
    <shared-job-filters
      :state-filter.sync="filter.state"
      :name-ko-filter.sync="filter.nameKo"
      :name-en-filter.sync="filter.nameEn"
      :name-cn-filter.sync="filter.nameCn"
      :state-options="stateOptions"
      @click="loadData"
    />

    <!-- Table Top -->
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="primary"
              @click="showRegisterModal"
            >
              {{ $t('register') }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      opacity="0.10"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <b-table
          responsive
          show-empty
          :items="items"
          :fields="fields"
          class="mb-0"
        >

          <!-- Column: id -->
          <template #cell(id)="data">
            <span
              class="align-middle ml-50"
              v-text="data.item.count"
            />
          </template>

          <!-- Column: State -->
          <template #cell(state)="data">
            <span class="align-text-top text-capitalize">{{ data.item.state }}</span>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                @click="editJob(data)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('edit') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="deleteJob(data)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <template slot="footer">
          <b-pagination
            v-model="filter.page"
            :per-page="perPage"
            hide-goto-end-buttons
            :total-rows="totalPage"
            align="center"
            @change="loadData"
          />
        </template>
      </b-card>
    </b-overlay>

    <b-modal
      ref="register-job-modal"
      cancel-variant="outline-secondary"
      ok-title="Register"
      cancel-title="Close"
      centered
      no-close-on-backdrop
      size="lg"
      title="Register Form"
      @ok="registerHandleOk"
      @close="resetFormData"
      @cancel="resetFormData"
    >
      <validation-observer ref="simpleRules">
        <b-form @submit.stop.prevent="registerHandleSubmit">
          <b-row>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('name_ko')"
                  rules="required"
                >
                  <label>{{ $t('name_ko') }}</label>
                  <b-form-input
                    v-model="formData.name_ko"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('name_en')"
                  rules="required"
                >
                  <label>{{ $t('name_en') }}</label>
                  <b-form-input
                    v-model="formData.name_en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('name_cn')"
                  rules="required"
                >
                  <label>{{ $t('name_cn') }}</label>
                  <b-form-input
                    v-model="formData.name_cn"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('state')"
                  rules="required"
                >
                  <label>{{ $t('state') }}</label>
                  <v-select
                    v-model="formData.state"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="stateOptions"
                    class="w-100"
                    :reduce="val => val.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      ref="edit-job-modal"
      cancel-variant="outline-secondary"
      ok-title="Update"
      cancel-title="Close"
      centered
      no-close-on-backdrop
      size="lg"
      title="Edit Form"
      @ok="editHandleOk"
      @close="resetFormData"
      @cancel="resetFormData"
    >
      <validation-observer ref="simpleRules">
        <b-form @submit.stop.prevent="registerHandleSubmit">
          <b-row>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('name_ko')"
                  rules="required"
                >
                  <label>{{ $t('name_ko') }}</label>
                  <b-form-input
                    v-model="formData.name_ko"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('name_en')"
                  rules="required"
                >
                  <label>{{ $t('name_en') }}</label>
                  <b-form-input
                    v-model="formData.name_en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('name_cn')"
                  rules="required"
                >
                  <label>{{ $t('name_cn') }}</label>
                  <b-form-input
                    v-model="formData.name_cn"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('state')"
                  rules="required"
                >
                  <label>{{ $t('state') }}</label>
                  <v-select
                    v-model="formData.state"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="stateOptions"
                    class="w-100"
                    :reduce="val => val.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>

import {
  required,
} from '@validations'

import {
  BRow,
  BCol,
  BCard,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Job from '@/models/Job'
import SharedJobFilters from '../Shared/Job/Filter.vue'

export default {
  name: 'AdminJob',
  components: {
    ValidationProvider,
    ValidationObserver,
    SharedJobFilters,
    vSelect,
    BRow,
    BCol,
    BCard,
    BTable,
    BButton,
    BDropdown,
    BDropdownItem,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BPagination,
    BOverlay,
  },
  data() {
    return {
      required,
      fields: [
        'id',
        'name_ko',
        'name_en',
        'name_cn',
        'state',
        'actions',
      ],
      items: [],
      loading: true,
      perPage: 10,
      totalPage: 0,
      filter: {
        state: typeof this.$route.query.state !== 'undefined' ? this.$route.query.state : '',
        nameKo: typeof this.$route.query.nameKo !== 'undefined' ? this.$route.query.nameKo : '',
        nameEn: typeof this.$route.query.nameEn !== 'undefined' ? this.$route.query.nameEn : '',
        nameCn: typeof this.$route.query.nameCn !== 'undefined' ? this.$route.query.nameCn : '',
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
      },
      stateOptions: [
        { label: 'Public', value: 'public' },
        { label: 'Private', value: 'private' },
        { label: 'Test', value: 'test' },
      ],
      formData: {
        id: '',
        name_ko: '',
        name_en: '',
        name_cn: '',
        state: '',
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData(page = 1) {
      this.loading = true

      const searchParams = {}
      searchParams.page = page
      Object.keys(this.filter).forEach(e => {
        if (this.filter[e] !== null && this.filter[e].length > 0) {
          searchParams[e] = this.filter[e]
        }
      })
      this.$router.replace({ query: searchParams })

      const response = await Job.list(searchParams)
      this.items = response.data.data
      this.totalPage = response.data.meta.pagination.total
      this.perPage = response.data.meta.pagination.per_page
      this.filter.page = response.data.meta.pagination.current_page
      this.loading = false
    },
    async deleteJob(data) {
      const doWeDelete = await this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (doWeDelete.value) {
        try {
          const response = await Job.destroy(data.item.id)
          this.$swal({
            title: 'Success!',
            text: response.data,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.loadData()
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: e.message,
            },
          })
        }
      }
    },
    showRegisterModal() {
      this.$refs['register-job-modal'].show()
    },
    registerHandleOk(e) {
      // Prevent modal from closing
      e.preventDefault()
      // Trigger submit handler
      this.registerHandleSubmit()
    },
    resetFormData() {
      this.formData = {
        id: '',
        name_ko: '',
        name_en: '',
        name_cn: '',
        state: '',
      }
    },
    async registerHandleSubmit() {
      const validator = await this.$refs.simpleRules.validate()

      if (validator) {
        try {
          const response = await Job.store(this.formData)

          this.$swal({
            title: 'Success!',
            text: response.data,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.loadData()
          this.$refs['register-job-modal'].hide()
          this.resetFormData()
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: e.message,
            },
          })
        }
      }
    },
    async editJob(data) {
      this.resetFormData()

      try {
        const response = await Job.view(data.item.id)
        this.formData = response.data
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        })
      }

      this.$refs['edit-job-modal'].show()
    },
    editHandleOk(e) {
      // Prevent modal from closing
      e.preventDefault()
      // Trigger submit handler
      this.editHandleSubmit()
    },
    async editHandleSubmit() {
      const validator = await this.$refs.simpleRules.validate()

      if (validator) {
        try {
          const response = await Job.update(this.formData)
          this.$swal({
            title: 'Success!',
            text: response.data,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.loadData()
          this.$refs['edit-job-modal'].hide()
          this.resetFormData()
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: e.message,
            },
          })
        }
      }
    },
  },

}
</script>

<style>

</style>
