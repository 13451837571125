import axios from '@/libs/axios'

class Job {
  static list(params) {
    return axios.post('admin/jobs', params)
  }

  static store(params) {
    return axios.post('admin/jobs/store', params)
  }

  static view(id) {
    return axios.post(`admin/jobs/view/${id}`)
  }

  static update(params) {
    return axios.post('admin/jobs/update', params)
  }

  static destroy(id) {
    return axios.post('admin/jobs/delete', { id })
  }
}

export default Job
